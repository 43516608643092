
import './assets/css/plugins.css';
import 'bootstrap/dist/css/bootstrap.min.css';


import './assets/css/style.css';
import './assets/css/default.css';
import './assets/css/new-fashion.css';
import './assets/css/modern.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import 'react-rangeslider/lib/index.css'
import './assets/css/responsive.css';
import './assets/css/base.css';
